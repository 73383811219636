import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  CallToActionBanner,
  Call,
  OutboundLink,
  Content,
  Reviews,
  InternalLink,
} from "@bluefin/components";
import { Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={2} stackable={true} className={"home-hero"}>
              <Background.Color color={"white"} textAlign={"left"}>
                <Header as={"div"} className={"h6"}>
                  Welcome To
                </Header>
                <Header as={"h1"}>{fishermanBusiness.name}</Header>
              </Background.Color>
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <CallToActionBanner stackable={true}>
            <CallToActionBanner.CTA>
              <Call
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                as={"button"}
                buttonColor={"primary"}
              />
            </CallToActionBanner.CTA>
            <CallToActionBanner.CTA>
              <Button
                as={OutboundLink}
                to={fishermanBusiness.ctas.orderOnline.link}
                primary={true}
                content={"Order Now"}
                size={"large"}
              />
            </CallToActionBanner.CTA>
          </CallToActionBanner>
          <Content stackable={true}>
            <Content.Markup truncate={true} paragraphs={2}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews.slice(0, 3)}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <Button
              to={"/about/"}
              primary={true}
              size={"large"}
              as={InternalLink}
            >
              See More
            </Button>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
      }
      ctas {
        orderOnline {
          link
        }
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
